import React from 'react';

export const SwitchLang = ({lang, type='dropdown'}) => {
  const langs = {
    "en-us": "English",
    "ar-ae": "Arabic",
    "ka-ka": "Georgian",
    "de-de": "German",
    "pl": "Polish",
    "ru": "Russian",
    "uk-uk": "Ukrainian"
  }
  switch(type) {
    case 'dropdown':
    return (
      <div className="dropdown">
        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="true">
          {langs[lang]}
          <span className="caret ml8"></span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
          {Object.keys(langs).map(langCode => {
            const url = langCode === 'en-us' ? '/' : '/' + langCode;
            return <li><a href={url}>{langs[langCode]}</a></li>
          })}
        </ul>
      </div>
    );
    case 'collapsable':
      return (
        <div>
          <a className="collapsed px15 py10 display-ib" role="button" data-toggle="collapse" href="#collapseLang" aria-expanded="false" aria-controls="collapseLang">
            {langs[lang]}
            <span className="caret ml8"></span>
          </a>
          <ul className="list-unstyled collapse px15" id="collapseLang">
            {Object.keys(langs).map(langCode => {
              const url = langCode === 'en-us' ? '/' : '/' + langCode;
              return <li><a className="px15 line-height-50" href={url}><small>{langs[langCode]}</small></a></li>
            })}
          </ul>
        </div>
      );
  }
}
