import React from "react"
import { graphql, StaticQuery } from "gatsby"
import $ from "jquery"
import PropTypes from "prop-types"
import { CustomLink} from "./common/custom-link.component"
import { Image } from "./common/image.component"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { SwitchLang } from "./switch-lang.component"
import { Slices } from "./slices.component"
import { linkFragment } from "../link-resolver"
import {origin} from "../utils/origin"
import { closeMenu } from "../utils/functions"

const query = graphql`
    query navbarQuery {
        prismic {
            allNavbars(uid: "header-navbar") {
                edges {
                    node {
                        logo
                        menu {
                            link {
                                ...link
                            }
                            link_text
                        }
                        _meta {
                            lang
                        }
                        buttons {
                            style
                            icon
                            link {
                                ...link
                            }
                            link_text
                            icon_position
                        }
                        body {
                            ... on PRISMIC_NavbarBodyMenu {
                                type
                                label
                                primary {
                                    nav_text
                                    nav_link {
                                        ...link
                                    }
                                }
                                fields {
                                    icon
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                            ... on PRISMIC_NavbarBodyCustom_script {
                                type
                                label
                                fields {
                                    script_html
                                    script_url
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`

export class Navbar extends React.Component {
  componentDidMount() {
    $("#quote").on("show.bs.modal", function() {
      $("#requestQuoteAlert").hide()
    })
    document.body.addEventListener("click", (event) => {
      if (event.target.tagName === "A"
        && !event.target.hasAttribute("data-toggle")
        && event.target.href
        && event.target.href.indexOf("#quote") > -1) {
        $("#quote").modal("show")
      }
    }, false)

    $("#requestQuote").ajaxForm(function(responseText, statusText, xhr, $form) {
      if (statusText === "success") {
        $("#requestQuoteAlert").removeClass("alert-danger").addClass("alert-success").text("Quote was sent successfully.").show()
        $("#requestQuote").resetForm()
        setTimeout(function() {
          $("#quote").modal("hide")
        }, 4000)
      } else {
        $("#requestQuoteAlert").removeClass("alert-success").addClass("alert-danger").text(responseText.Message).show()
      }
      document.getElementById("formsubmit").disabled = false
    })

    const path = $(".top-nav .nav-pills li a")

    path.each(function() {
      if (window.location.pathname === $(this)[0].pathname) {
        $(this).parent().siblings().removeClass("active")
        $(this).parent().addClass("active")
      }
    })
  }

  handleUrl(url) {
    return url && url.indexOf("#") > -1
      ? "#" + url.split("#")[1]
      : url.includes(origin)
        ? url.replace(origin, "/")
        : url;
  }

  render() {
    const { slides, lang } = this.props
    const noBG = (slides.length === 1
      && (!slides[0].background_image))
    return <StaticQuery
      query={query}
      render={withPreview(
        data => {
          const item = data.prismic.allNavbars.edges
            .find(item => item.node._meta.lang === lang)
          if (item) {
            const { menu, logo, buttons } = item.node
            return (
              <div className="top-navbar">
                <div className="top-nav_mob visible-xs visible-sm">
                  <div className="container">
                    <div className="row">
                      <div className="col-xs-2">
                        <a href="/" className="nav-image-wrapper"><Image image={logo}/></a>
                      </div>
                      <div className="col-xs-8 mt10">
                        <a style={{ color: "#fff", fontSize: "11px" }} data-toggle="modal" href="#quote">Request a
                          Quote</a>
                        &nbsp;&nbsp;
                        <a className="nav-customer-login" style={{ color: "#fff", fontSize: "11px" }}
                           href="/app/login.aspx?ReturnUrl=%2fapp%2fdefault.aspx">Customer
                          Login</a>
                      </div>
                      <div className="col-xs-1" style={{ marginTop: "12px" }}>
                        <a data-toggle="modal" href="#menu">
                          <span className="glyphicon glyphicon-menu-hamburger" aria-hidden="true"/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${noBG ? "top-nav_alt" : "top-nav"} hidden-xs hidden-sm`}>
                  <div className="container">
                    <div className="row">
                      <div className="col-xs-3 col-sm-2">
                        <div className="logo"><a href="/" style={{ width: "78px" }} className="center-block"><Image
                          image={logo} className="navbar-logo"/></a></div>
                      </div>
                      <div className="col-xs-9 col-sm-10">
                        <br/>
                        <div className="row hidden-xs">
                          <div className="col-sm-12 text-right">
                            <div className={`display-ib mr25 ${noBG ? "" : "text-white"}`}>
                              <SwitchLang lang={lang}/>
                            </div>
                            {buttons.map(btn => {
                              if(!btn.link) {
                                return null;
                              }
                              switch (btn.icon_position){
                                  case 'right':
                                      return (
                                          <a data-toggle="modal"
                                             href={this.handleUrl(btn.link.url)}
                                             className={`ml15 btn btn-${btn.style} btn-border-10`}>
                                              {btn.link_text}
                                              {btn.icon ?
                                                  <Image className="display-ib ml5" style={{ width: "15px", height: "15px" }}
                                                         image={btn.icon}/> : null}
                                          </a>
                                      )
                                  case 'car button':
                                      const [firstLine, ...other] = btn.link_text.split(' ');
                                      const secondLine = other.join(' ');
                                      return (
                                          <a data-toggle="modal"
                                             href={this.handleUrl(btn.link.url)}
                                             className={`ml15 btn btn-${btn.style} btn-border-10 navbar-car-button`}>
                                              {firstLine}
                                              {btn.icon ?
                                                  <Image className="display-ib ml8" style={{ width: "30px", height: "15px" }}
                                                         image={btn.icon}/> : null}
                                              <br/>
                                              {secondLine}
                                          </a>
                                      )
                                  case 'left':
                                  default:
                                      return (
                                          <a data-toggle="modal"
                                             href={this.handleUrl(btn.link.url)}
                                             className={`ml15 btn btn-${btn.style} btn-border-10`}>
                                              {btn.icon ?
                                                  <Image className="display-ib mr5" style={{ width: "15px", height: "15px" }}
                                                         image={btn.icon}/> : null}
                                              {btn.link_text}
                                          </a>
                                      )
                              }
                            })
                            }
                          </div>
                        </div>
                        <div className="row visible-xs">
                          <div className="col-sm-12 text-right">
                            {buttons.map(btn => {
                              if(!btn.link) {
                                return null;
                              }
                              return (
                                <a data-toggle="modal"
                                   href={btn.link.url && btn.link.url.indexOf("#") > -1 ? "#" + btn.link.url.split("#")[1] : btn.link.url}
                                   className={`btn btn-${btn.style}`}>
                                  {btn.icon ?
                                    <Image className="display-ib mr15" style={{ width: "15px", height: "15px" }}
                                           image={btn.icon}/> : null}
                                  {btn.link_text}
                                </a>
                              )
                            })}
                            <a className="btn btn-primary" data-toggle="modal" href="#menu">Menu</a>
                          </div>
                        </div>
                        <br className="hidden-xs"/>
                        <ul className="nav nav-pills pull-right hidden-xs">
                          {
                            menu.map(item => (
                              <li>
                                <CustomLink activeClassName="active" link={item.link}>{item.link_text}</CustomLink>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="menu">
                  <div className="modal-dialog modal-dialog_blue">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                          <span className="glyphicon glyphicon-remove" aria-hidden="true"/>
                        </button>
                        <h4 className="modal-title">&nbsp;</h4>
                      </div>
                      <div className="modal-body">
                        <ul className="nav">
                          {buttons.map(btn => {
                            if(!btn.link) {
                              return null;
                            }
                            return (
                              <li>
                                <a data-toggle="modal"
                                   href={btn.link.url && btn.link.url.indexOf("#") > -1 ? "#" + btn.link.url.split("#")[1] : btn.link.url}>
                                  {btn.icon ?
                                    <Image className="display-ib mr15" style={{ width: "15px", height: "15px" }}
                                           image={btn.icon}/> : null}
                                  {btn.link_text}
                                </a>
                              </li>
                            )})}
                            <li>
                                <div className="px15">
                                    <hr/>
                                </div>
                            </li>
                          {
                            menu.map(item => (
                              <li>
                                <CustomLink onClickLink={closeMenu} activeClassName="active" link={item.link}>{item.link_text}</CustomLink>
                              </li>
                            ))
                          }
                          <li>
                            <div className="px15">
                              <hr/>
                            </div>
                            <SwitchLang lang={lang} type="collapsable"/>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <Slices body={item.node.body}/>
              </div>
            )
          }
          return null
        }, query, [linkFragment])}
        />
      }
}

Navbar.propTypes = {
  lang: PropTypes.string,
  slides: PropTypes.array,
}

Navbar.defaultProps = {
  lang: "en-us",
  slides: [],
}

