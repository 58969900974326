import React from "react"
import { graphql, StaticQuery } from "gatsby"
import $ from "jquery"
import { Image } from "./common/image.component"
import { PlainStructuredText } from "./common/plain-structured-text.component"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { Slices } from "./slices.component"
import { linkFragment } from "../link-resolver"
import { RichText } from "./common/rich-text.component"

const query = graphql`
    query footerQuery {
        prismic {
            allFooters(uid: "footer") {
                edges {
                    node {
                        _meta {
                            lang
                        }
                        body {
                            ... on PRISMIC_FooterBodyCustom_script {
                                type
                                label
                                fields {
                                    script_html
                                    script_url
                                }
                            }
                            ... on PRISMIC_FooterBodyMenu {
                                type
                                label
                                primary {
                                    nav_text
                                    nav_link {
                                        ...link
                                    }
                                }
                                fields {
                                    icon
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                        }
                        phone
                        partners_text
                        partners {
                            image
                        }
                        fax
                        email
                        copyright
                    }
                }
            }
        }
    }

`

export class Footer extends React.Component {
  componentDidMount() {
      const carousel = $(".partners")
      carousel.owlCarousel({
        items: 5,
        itemsTablet: 3,
        itemsMobile: 2,
      })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const carousel = $(".partners")
    carousel.owlCarousel({
      items: 5,
      itemsTablet: 3,
      itemsMobile: 2,
    })
  }

  componentWillMount() {
    console.log("component will mount")
  }

  render() {
    return (
      <StaticQuery query={
        query
      } render={withPreview(data => {
        const lang = this.props.lang || "en-us"
         let item = data.prismic.allFooters.edges
           .find(item => item.node._meta.lang === lang);
        if(!item || !item.node || !item.node.body) {
          item = data.prismic.allFooters.edges
            .find(item => item.node._meta.lang === "en-us");
        }
        const { copyright, email, fax, phone, partners_text, partners, body }
          = item.node;

        return (
          <footer>
            <div className="container">
              <br/>
              <div className="row">
                <div className="col-sm-3">
                  <h2 className="visible-lg">
                    <PlainStructuredText structuredText={partners_text}/>
                  </h2>
                  <h3 className="visible-md visible-xs" style={{ color: "#fff" }}>
                    <PlainStructuredText structuredText={partners_text}/>
                  </h3>
                  <br className="visible-sm"/>
                  <h4 className="visible-sm" style={{ color: "#fff" }}>
                    <PlainStructuredText structuredText={partners_text}/>
                  </h4>
                </div>
                <div className="col-sm-9">
                  <div className="partners">
                    {
                      partners.map(partner => (
                        <div className="item">
                          <Image className="img-responsive partner center-block" image={partner.image}/>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
              <hr/>
              <section>
                <Slices body={body}/>
                <div className="row">
                  <div className="col-sm-6">
                    <div>
                      Copyright {new Date().getFullYear()} All right reserved
                    </div>
                    <div>{copyright}</div>
                  </div>
                  <div className="col-sm-4 col-sm-offset-2">
                    <div><RichText className="display-ib home-footer-phone" render={phone}/></div>
                    <div>Fax: {fax}</div>
                    <div>Email: {email}</div>
                  </div>
                </div>
              </section>
            </div>
          </footer>
        )
      }, query, [linkFragment])
      }/>
    )}
}

