/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Footer } from "./footer.component"

const Layout = ({ children, className, lang }) => {
  return (
    <>
      <div id="main-content" className={className || ''}>
      {children}
      </div>
      <Footer lang={lang} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
